.ol-control {
  position: absolute;
}

.ol-control > button {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  font-size: 25px;
}

.ol-full-screen {
  top: 0;
  right: 0;
}
